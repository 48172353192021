/**
 * @copyright 2021 @ DigiNet
 * @author XUANLOC
 * @create 28/9/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeEvery } from 'redux-saga/effects';
import Api from '../../../services/api';
import { types } from './W09F2092_actions';

export default function W09F2092Sagas() {
    return [watchGetDynamicColumn()];
}

export function* getDynamicColumn(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w09f2092/load-dynamic-column', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('get dynamic column error', e);
    }
}

export function* watchGetDynamicColumn() {
    while (true) {
        const watcher = yield takeEvery(types.W09F2092_GET_DYNAMIC_COLUMN, getDynamicColumn);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}
