
export const types = {
    W09F4000_LOAD_FORM: "W09F4000_LOAD_FORM",
    W09F4000_GET_LIST_EMPLOYEE_DEPARTMENT: "W09F4000_GET_LIST_EMPLOYEE_DEPARTMENT",
    W09F4000_GET_LIST_CBO_POSITION: "W09F4000_GET_LIST_CBO_POSITION",
    W09F4000_GET_LIST_CBO_POSITION_SUCCESS: "W09F4000_GET_LIST_CBO_POSITION_SUCCESS",
    W09F4000_LOAD_FILTER: "W09F4000_LOAD_FILTER",
};

export function getDataForm(params, cb) {
    return {
        type: types.W09F4000_LOAD_FORM,
        params,
        cb
    };
}

export function getListEmployeeDepartment(params, cb) {
    return {
        type: types.W09F4000_GET_LIST_EMPLOYEE_DEPARTMENT,
        params,
        cb
    };
}

export function getListCboPosition(params, cb) {
    return {
        type: types.W09F4000_GET_LIST_CBO_POSITION,
        params,
        cb
    };
}

export function loadFilter(params, cb) {
    return {
        type: types.W09F4000_LOAD_FILTER,
        params,
        cb
    };
}
