/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/11/2021
 * @Example
 */
export const types = {
    W09F2291_GET_CBO_REASON        : "W09F2291_GET_CBO_REASON",
    W09F2291_GET_CBO_REASON_SUCCESS: "W09F2291_GET_CBO_REASON_SUCCESS",
    W09F2291_GET_COLUMN            : "W09F2291_GET_COLUMN",
    W09F2291_GET_COLUMN_SUCCESS    : "W09F2291_GET_COLUMN_SUCCESS",
    W09F2291_GET_CAPTION           : "W09F2291_GET_CAPTION",
    W09F2291_GET_CAPTION_SUCCESS   : "W09F2291_GET_CAPTION_SUCCESS",
    W09F2291_GET_GRID              : "W09F2291_GET_GRID",
    W09F2291_GET_EMPLOYEE          : "W09F2291_GET_EMPLOYEE",
    W09F2291_SAVE                  : "W09F2291_SAVE",
    W09F2291_CONFIRM_SAVE          : "W09F2291_CONFIRM_SAVE",
    W09F2291_EDIT                  : "W09F2291_EDIT",
    W09F2291_CONFIRM_EDIT          : "W09F2291_CONFIRM_EDIT",
};

export function getCboReason(cb) {
    return {
        type: types.W09F2291_GET_CBO_REASON,
        cb
    };
}

export function getColumns(cb) {
    return {
        type: types.W09F2291_GET_COLUMN,
        cb
    };
}

export function getCaptions(cb) {
    return {
        type: types.W09F2291_GET_CAPTION,
        cb
    };
}

export function getGrids(params, cb) {
    return {
        type: types.W09F2291_GET_GRID,
        params,
        cb
    };
}

export function getEmployee(params, cb) {
    return {
        type: types.W09F2291_GET_EMPLOYEE,
        params,
        cb
    };
}

export function save(params, cb) {
    return {
        type: types.W09F2291_SAVE,
        params,
        cb
    };
}

export function confirmSave(params, cb) {
    return {
        type: types.W09F2291_CONFIRM_SAVE,
        params,
        cb
    };
}

export function edit(params, cb) {
    return {
        type: types.W09F2291_EDIT,
        params,
        cb
    };
}

export function confirmEdit(params, cb) {
    return {
        type: types.W09F2291_CONFIRM_EDIT,
        params,
        cb
    };
}

