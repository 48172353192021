/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/11/2021
 * @Example
 */

import {types} from "./W09F2291_actions";

const initialState = {
    getCboReasons: [],
    getColumns   : [],
    getCaptions  : [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2291_GET_CBO_REASON_SUCCESS: {
            return {
                ...state,
                getCboReasons: action.data
            };
        }
        case types.W09F2291_GET_COLUMN_SUCCESS: {
            return {
                ...state,
                getColumns: action.data
            };
        }
        case types.W09F2291_GET_CAPTION_SUCCESS: {
            return {
                ...state,
                getCaptions: action.data
            };
        }
        default:
            return state;
    }
}
