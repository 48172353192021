/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 12/10/2021
 * @Example
 */

export const types = {
    W09F2292_GET_DYNAMIC_COLUMN_SALARY: 'W09F2292_GET_DYNAMIC_COLUMN_SALARY',
    W09F2292_GET_DYNAMIC_COLUMN_CAPTION: 'W09F2292_GET_DYNAMIC_COLUMN_CAPTION',
};

export function getDynamicColumnSalary(params, cb) {
    return {
        type: types.W09F2292_GET_DYNAMIC_COLUMN_SALARY,
        params,
        cb,
    };
}

export function getDynamicColumnCaption(params, cb) {
    return {
        type: types.W09F2292_GET_DYNAMIC_COLUMN_CAPTION,
        params,
        cb,
    };
}
