/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 2/6/2020
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2021_actions";
import { delay } from "redux-saga";

export default function W09F2021Sagas() {
    return [watchGetForm(), watchGetIsMonthDayOffBirth()];
}

export function* getForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2021/load-form", data.params);
        if (response && response.data) {
            yield put({ type: types.W09F2021_LOAD_FORM_SUCCESS, data: response.data });
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetForm() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2021_LOAD_FORM, getForm);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getIsMonthDayOffBirth(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2021/month-day-off-birth", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get form error");
    }
}

export function* watchGetIsMonthDayOffBirth() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2021_LOAD_IS_MONTH_DAY_OFF_BIRTH, getIsMonthDayOffBirth);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
