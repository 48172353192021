/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

import { types } from "./W09F2091_actions";

const initialState = {
    getColumns: [],
    getCoefficients: [],
    getCboDivisions:[],
};

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case types.W09F2091_GET_COEFFICIENT_SUCCESS: {
            return { ...state, getCoefficients: action.data };
        }
        case types.W09F2091_GET_COLUMN_SUCCESS: {
            return { ...state, getColumns: action.data };
        }
        case types.W09F2091_GET_CBO_DIVISION_SUCCESS: {
            return { ...state, getCboDivisions: action.data };
        }
        default:
            return state;
    }
}
