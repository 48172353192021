/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

export const types = {
    W09F2091_GET_DATA_FORM: "W09F2091_GET_DATA_FORM",
    W09F2091_GET_DATA_EMPLOYEE_BY_ID: "W09F2091_GET_DATA_EMPLOYEE_BY_ID",
    W09F2091_GET_COLUMN: "W09F2091_GET_COLUMN",
    W09F2091_GET_COLUMN_SUCCESS: "W09F2091_GET_COLUMN_SUCCESS",
    W09F2091_GET_COEFFICIENT: "W09F2091_GET_COEFFICIENT",
    W09F2091_GET_COEFFICIENT_SUCCESS: "W09F2091_GET_COEFFICIENT_SUCCESS",
    W09F2091_GET_CBO_DIVISION: "W09F2091_GET_CBO_DIVISION",
    W09F2091_GET_CBO_DIVISION_SUCCESS: "W09F2091_GET_CBO_DIVISION_SUCCESS",
    W09F2091_GET_CBO_DEPARTMENT: "W09F2091_GET_CBO_DEPARTMENT",
    W09F2091_SAVE: "W09F2091_SAVE",
    W09F2091_UPDATE: "W09F2091_UPDATE",
};

export function getDataForm(params, cb) {
    return {
        type: types.W09F2091_GET_DATA_FORM,
        params,
        cb
    };
}

export function getDataEmployeeByID(params, cb) {
    return {
        type: types.W09F2091_GET_DATA_EMPLOYEE_BY_ID,
        params,
        cb
    };
}

export function getColumns(cb) {
    return {
        type: types.W09F2091_GET_COLUMN,
        cb
    };
}

export function getCoefficients(cb) {
    return {
        type: types.W09F2091_GET_COEFFICIENT,
        cb
    };
}


export function getCboDivisions(cb) {
    return {
        type: types.W09F2091_GET_CBO_DIVISION,
        cb
    };
}


export function getCboDepartments(params, cb) {
    return {
        type: types.W09F2091_GET_CBO_DEPARTMENT,
        params,
        cb
    };
}

export function save(params, cb) {
    return {
        type: types.W09F2091_SAVE,
        params,
        cb
    };
}


export function update(params, cb) {
    return {
        type: types.W09F2091_UPDATE,
        params,
        cb
    };
}