/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/24/2021
 * @Example
 */

import Api from "../../../services/api";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types } from "./W09F2090_actions";
import { delay } from "redux-saga";

export default function W09F2090Sagas() {
    return [
        watchGetDataGrid(),
        watchDeleteRow(),
        watchGetCboAppStatus(),
        watchGetCboDivision(),
        watchGetCboDepartment(),
    ];
}

export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2090/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data error");
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2090_GET_DATA_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2090/load-cbo-app-status", data.params);
        if (response && response.data) {
           yield put({type:types.W09F2090_GET_CBO_APP_STATUS_SUCCESS, data: response.data})
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo app status error");
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2090_GET_CBO_APP_STATUS, getCboAppStatus);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboDivision(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w09f2090/load-cbo-division");
        if (response && response.data) {
           yield put({type:types.W09F2090_GET_CBO_DIVISION_SUCCESS, data: response.data})
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo division error");
    }
}

export function* watchGetCboDivision() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2090_GET_CBO_DIVISION, getCboDivision);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboDepartment(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w09f2090/load-cbo-department", data.params);
        if (response && response.data) {
          yield  put({type:types.W09F2090_GET_CBO_DEPARTMENT_SUCCESS, data: response.data})
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo department error");
    }
}

export function* watchGetCboDepartment() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2090_GET_CBO_DEPARTMENT, getCboDepartment);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w09f2090/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete data error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W09F2090_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
