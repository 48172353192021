/**
 * @copyright 2020 @ DigiNet
 * @author DINHTHIEN
 * @create 7/17/2020
 * @Example
 */
export const types = {
  W09F2021_LOAD_FORM: "W09F2021_LOAD_FORM",
  W09F2021_LOAD_FORM_SUCCESS: "W09F2021_LOAD_FORM_SUCCESS",
  W09F2021_LOAD_IS_MONTH_DAY_OFF_BIRTH: "W09F2021_LOAD_IS_MONTH_DAY_OFF_BIRTH"
};

export function getForm(params, cb) {
  return {
    type: types.W09F2021_LOAD_FORM,
    params,
    cb
  };
};

export function getIsMonthDayOffBirth(cb) {
  return {
    type: types.W09F2021_LOAD_IS_MONTH_DAY_OFF_BIRTH,
    cb
  };
};
